import React, {useEffect} from 'react';
import {graphql} from 'gatsby';
import {Link, Trans, useTranslation, useI18next} from 'gatsby-plugin-react-i18next';
import Layout from '../components/layout';
import SEO from '../components/seo';
import InnerPage from "../components/innerPage";
import company_logo from "../../content/assets/favicon/company_logo.png";
import parse from "html-react-parser";

/**
 * contact us page
 * @returns {JSX.Element}
 * @private
 */
const ContactUs = () => {
    const {t} = useTranslation();
    const {languages, originalPath} = useI18next();

    const breadcrumbs = [
        {
            title: t('contactUs'),
        }
    ]


    useEffect(() => {

    }, [])

    return (
        <Layout>
            <SEO title={t('contactUs')}/>
            <InnerPage
                breadcrumbs={breadcrumbs}
                pageTitle={t('contactUs')}
            >
                <section id="contact-us" className={"py-0"}>
                    <div className="container" data-aos="fade-up" >
                        <h3 className={"logo"}>
                            <img className={"align-bottom mr-5px mh-30px"} src={company_logo} alt=""/>
                            {t("LovingKids")}
                        </h3>
                        <p>
                            {t('companyAddress1')}<br/>
                            {t('companyAddress2')}<br/>
                            {t('companyAddress3')}<br/><br/>

                            <strong>{t('phone')}:</strong>&nbsp;
                            <a href={"tel:+85221173665"}
                               // className={"text-white"}
                            >
                                +852 2117 3665
                            </a><br/>
                            <strong>{t('email')}:</strong>&nbsp;
                            <a href={"mailto: info@lovingkids.org.hk"}
                               // className={"text-white"}
                            >
                                info@lovingkids.org.hk
                            </a><br/><br/>
                            <strong>{t('officeHour')}:</strong>&nbsp;<br/>
                            <strong>{t('mondayToThursdayAndSaturday')}:</strong> 10:00 a.m. - 07:00 p.m. <br/>
                            <strong>{t('friday')}:</strong> 12:00 p.m. - 09:00 p.m.

                            <br/><br/>
                            {parse(t('callBeforeComeHtml'))}

                            <div className={"pt-4"}>
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3348.447090016077!2d114.15526398447037!3d22.33298473082974!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x340400b2a3c5b9e5%3A0x6edda16ca1cd3644!2z5oSb6IuX6KGM5YuV56S-5Y2A5pyN5YuZ5Lit5b-D!5e0!3m2!1szh-TW!2shk!4v1658251331201!5m2!1szh-TW!2shk"
                                    width="600" height="450"  allowFullScreen="" loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>

                        </p>
                    </div>
                </section>
            </InnerPage>
        </Layout>
    );
};

export default ContactUs;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;